import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import { faPhone } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Header = ({ location }) => {
  const [isActive, setisActive] = React.useState(false)
  return (
    <nav
      className="navbar is-fixed-top"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <StaticImage
          src="../images/logo.png"
          placeholder="none"
          quality={90}
          alt="Logo"
          width={68}
          layout="fixed"
        />
        <Link to="/" className="navbar-item navbar-logo">
          <div>
            <div style={{ fontSize: "0.8rem" }}>札幌不動産のエキスパート</div>
            <div style={{ fontSize: "1.2rem" }}>株式会社 ファースト</div>
          </div>
        </Link>
        <div
          role="button"
          className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="headerMenu"
          onClick={() => {
            setisActive(!isActive)
          }}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </div>
      </div>
      <div
        id="headerMenu"
        className={`navbar-menu ${isActive ? "is-active" : ""}`}
      >
        <div className="navbar-end">
          <div className="navbar-item has-text-centered">
            <a
              className="button is-dark is-fullWidth"
              href="tel:011-596-7480"
              style={{ height: "3.3em" }}
            >
              <span>
                <span style={{ fontSize: "0.7em" }}>
                  お気軽にお問合せください（受付: 平日 10:00 - 19:00）
                </span>
                <br />
                <span className="icon">
                  <FontAwesomeIcon icon={faPhone} />
                </span>
                <span style={{ fontSize: "1.2em" }}>011-596-7480</span>
              </span>
            </a>
          </div>
          <div className="navbar-item has-dropdown is-hoverable">
            <div
              className={
                location.pathname.match(/buy|sell/)
                  ? "navbar-link menu-active dropdown"
                  : "navbar-link"
              }
            >
              不動産売買
            </div>
            <div className="navbar-dropdown">
              <Link
                to="/buy"
                className="navbar-item"
                partiallyActive={true}
                activeClassName="menu-active"
              >
                不動産の購入
              </Link>
              <Link
                to="/sell"
                className="navbar-item"
                partiallyActive={true}
                activeClassName="menu-active"
              >
                不動産の売却
              </Link>
            </div>
          </div>
          <div className="navbar-item has-dropdown is-hoverable">
            <div
              className={
                location.pathname.match(/rent|lend/)
                  ? "navbar-link menu-active dropdown"
                  : "navbar-link"
              }
            >
              不動産賃貸
            </div>
            <div className="navbar-dropdown">
              <Link
                to="/rent"
                className="navbar-item"
                partiallyActive={true}
                activeClassName="menu-active"
              >
                不動産の借り入れ
              </Link>
              <Link
                to="/lend"
                className="navbar-item"
                partiallyActive={true}
                activeClassName="menu-active"
              >
                不動産の貸し出し
              </Link>
            </div>
          </div>
          <Link
            to="/invest"
            className="navbar-item"
            partiallyActive={true}
            activeClassName="menu-active"
          >
            不動産投資
          </Link>
          <Link
            to="/inherit"
            className="navbar-item"
            partiallyActive={true}
            activeClassName="menu-active"
          >
            不動産相続
          </Link>
          <Link
            to="/remodel"
            className="navbar-item"
            partiallyActive={true}
            activeClassName="menu-active"
          >
            リフォーム
          </Link>
          <Link
            to="/company"
            className="navbar-item"
            partiallyActive={true}
            activeClassName="menu-active"
          >
            企業情報
          </Link>
          <Link
            to="/contact"
            className="navbar-item"
            partiallyActive={true}
            activeClassName="menu-active"
          >
            お問い合わせ
          </Link>
        </div>
      </div>
    </nav>
  )
}

export default Header
