import { faPhone, faFax } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
const Footer = () => (
  <footer className="footer has-text-centered mt-5">
    <div
      className="columns is-vcentered is-centered"
      style={{
        maxWidth: "1400px",
        margin: "0 auto",
      }}
    >
      <div className="column is-4">
        <StaticImage
          src="../images/logo.png"
          placeholder="none"
          quality={90}
          alt="Logo"
          width={50}
        />
        <span
          className="title is-5"
          style={{
            verticalAlign: "middle",
            lineHeight: "50px",
          }}
        >
          株式会社 ファースト
        </span>
      </div>
      <div className="column is-4">
        <div
          style={{ margin: "0 auto", maxWidth: "20em" }}
          className="has-text-left pl-5"
        >
          <p>〒064-0808</p>
          <p>札幌市中央区南8条西7丁目1782-23</p>
          <p>KSコート102号室</p>
        </div>
      </div>
      <div className="column is-4">
        <div>お気軽にお問い合わせください</div>
        <div>
          <div style={{ fontSize: "1.3em" }}>
            <FontAwesomeIcon icon={faPhone} /> 011-596-7480 <br />
            <FontAwesomeIcon icon={faFax} /> 011-596-7490
          </div>
          (受付: 平日 10:00 - 19:00)
        </div>
      </div>
    </div>

    <p className="mt-4">© 2021 株式会社 ファースト</p>
  </footer>
)

export default Footer
